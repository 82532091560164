import {Color, Fog, Mesh, MeshStandardMaterial} from "three";

console.log('app started')

import * as THREE from 'three';
import {OrbitControls as Controls} from 'three/examples/jsm/controls/OrbitControls';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'

const MODE_SEPARATE = 0;
const MODE_FULL = 1;

let mode = MODE_FULL;

const defaultOptions = {
    transparentBackground: false,
    controls: {
        enableDamping: true,
        minDistance: 10,
        maxDistance: 20,
        minPolarAngle: 0.75,
        maxPolarAngle: 1.75,
        position: {
            x: 4,
            y: -3,
            z: -16,
        }
    }
}

async function load() {
    let options = defaultOptions;
    if (window.previewerOptions) {
        options = {...defaultOptions, ...window.previewerOptions};
    }

    console.info('Previewer options', options)
    let objects = {}

    const sceneContainer = document.getElementById('canvas');
    const modelHref = document.querySelector('[data-model]');

    let model = modelHref.attributes.href?.value;

    if (!model) {
        return;
    }

    const width = sceneContainer.clientWidth;
    const height = sceneContainer.clientHeight;

    const loader = new GLTFLoader();

    const scene = new THREE.Scene();

    if (!options.transparentBackground) {
        scene.background = new Color(0.8, 0.8, 0.8)
        scene.fog = new Fog(new Color(1, 1, 1), 1, 1000)
    }

    const camera = new THREE.PerspectiveCamera(60, width / height, 0.1, 1200);

    const renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.toneMapping = THREE.ReinhardToneMapping
    renderer.physicallyCorrectLights = true

    setRenderSize()

    sceneContainer.appendChild(renderer.domElement);

    let controls = new Controls(camera, renderer.domElement);

    for ( const [key, value] of Object.entries(options.controls)) {
        controls[key] = value;
    }

    //  controls.minAzimuthAngle = 1.4
    //  controls.maxAzimuthAngle = 1.7

    camera.position.z = options.controls.position.z;
    camera.position.x = options.controls.position.x;
    camera.position.y = options.controls.position.y;

    const gltf = await loader.loadAsync(model)
    console.log(gltf)

    let cameraLight = null;

    gltf.scene.traverse(obj => {
        objects[obj.name] = obj

        if (!obj.isGroup) {
            if (obj.name === 'Light') {
                cameraLight = obj;
                obj.castShadow = true
                obj.receiveShadow = true
            }

            if (!["Light001_1", "Light002_1"].includes(obj.name)) {
                // obj.visible = false
                obj.castShadow = true
                obj.receiveShadow = true
            }

            if (obj.shadow) {
                obj.shadow.mapSize.width = 2048 // default
                obj.shadow.mapSize.height = 2048 // default
                obj.shadow.camera.near = 0.5 // default
                obj.shadow.camera.far = 100 // default
                obj.shadow.radius = 3
                obj.shadow.bias = -0.00001
            }

            if (obj.intensity) {
                obj.intensity = obj.intensity * 20;
                obj.distance = 20000;
            }
        }

    });

    scene.add(gltf.scene)

    window.setColor = function (r, g, b) {
        const targets = ["L_Karop", "B_Karop", "Cube015_2"]
        scene.traverse((obj) => {
            if (obj.isMesh && obj.material) {
                const found = targets.reduce(
                    (acc, current) => {
                        if (obj.name.indexOf(current) > -1) {
                            acc = true
                        }
                        return acc
                    },
                    false
                )

                if (found) {
                    obj.material.color = new THREE.Color(r, g, b)
                }
            }
        })

    }

    window.setProductMode = (mode) => {
        const targets = ["L_Karop", "B_Karop"]

        console.log('setProductMode', mode)

        scene.traverse((obj) => {
            if (obj.isMesh) {
                const found = targets.reduce(
                    (acc, current) => {
                        if (obj.name.indexOf(current) > -1) {
                            acc = true
                        }
                        return acc
                    },
                    false
                )

                if (found) {
                    obj.visible = !!mode
                }
            }

        })
    }

    function animate() {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);

        cameraLight.position.y = camera.position.y
        cameraLight.position.x = camera.position.x
        cameraLight.position.z = camera.position.z
    }

    function setRenderSize() {
        const width = sceneContainer.clientWidth;
        const height = sceneContainer.clientHeight;

        console.log(width, height)
        renderer.setSize(width, height);
        camera.aspect = width / height
    }

    function addFloor() {
        const geometry = new THREE.BoxGeometry(50, 0.1, 50)
        const material = new THREE.MeshStandardMaterial({color: 0xffffff});
        const floor = new THREE.Mesh(geometry, material);

        floor.receiveShadow = true
        floor.position.y = -5.7
        scene.add(floor);
    }

    window.addEventListener('resize', function () {
        setRenderSize()
    })

    if (!options.transparentBackground) {
        addFloor();
    }

    animate();
}

load();

